(function () {
    const filters = document.querySelectorAll('.resources-filter-form');
    filters.forEach(filter => {
        const resetButton = filter.querySelector('.reset');
        const yearSelect = filter.querySelector('.custom-select');
        resetButton.addEventListener('click', function (e) {
            const customSelectValue = filter.querySelector('.nativejs-select__placeholder-value');
            yearSelect.value = '';
            customSelectValue.textContent = 'All'
        })
    })
})()