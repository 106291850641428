import Chart from 'chart.js/auto'

(async function () {
    const diagrams = document.querySelectorAll('.diagram');
    diagrams.forEach(diagram => {
        const canvas = diagram.getElementsByTagName('canvas');
        let data = [];
        const dataList = diagram.querySelectorAll('.diagram-data li');
        dataList.forEach(dataItem => {
            const color = dataItem.querySelector('.color').style.backgroundColor;
            const amount = dataItem.querySelector('.amount').textContent;
            const label = dataItem.querySelector('.label b').textContent.slice(0, -1);
            data.push({color, amount, label})
        })
        new Chart(
            canvas,
            {
                type: 'pie',
                data: {
                    labels: data.map(row => row.label),
                    datasets: [
                        {
                            label: '',
                            data: data.map(row => row.amount),
                            borderColor: 'transparent',
                            backgroundColor: data.map(row => row.color),
                        }
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                           display: false
                        }
                    }
                }
            }
        );
    })
})()