(function () {
    var currentPage = 1; // Set the initial page

    // Add click event for pagination buttons
    $(document).on('click', '.meetings .ajax-pagination', function(e) {
        e.preventDefault();

        currentPage = $(this).data('page'); // Update the current page
        var section = $(this).data('section');
        
        var pageData = 'action=paginate_meetings&paged=' + currentPage + '&section=' + section;
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: _wpObject.ajaxUrl,
            data: pageData,
            success: function(response) {
                if(response.success) {
                    if (section === 'past') {
                        $('.past-meetings.resources-section__copy').html(response.data);
                    }
                    else if (section === 'future') {
                    $('.future-meetings.resources-section__copy').html(response.data);
                    }
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    });
})();
