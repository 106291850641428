(function () {

    const $header = document.querySelector('.header');
    const $menu = document.querySelector('.main-nav');
    const $hamburger = document.querySelector('.burger');
    let isMenuOpened = false;
    $hamburger.addEventListener('click', function () {
        this.classList.toggle('active')
        $menu.classList.toggle('opened');
        $header.classList.toggle('menu-opened');
        isMenuOpened = !isMenuOpened;

        if (isMenuOpened) {
            bodyScrollLock.disableBodyScroll($menu);
        } else {
            bodyScrollLock.clearAllBodyScrollLocks();
        }
    });

    window.addEventListener('resize', debounce(function () {
        if (isMenuOpened && window.innerWidth >= 768) {
            bodyScrollLock.clearAllBodyScrollLocks();
        }
    }))

    const $subMenuLinks = document.querySelectorAll('.menu-item-has-children > a');

    $subMenuLinks.forEach(el => {
        el.addEventListener('click', e => {
            if (window.innerWidth < 1025) {
                let parent = e.target.parentNode
                let subMenu = parent.querySelector('.sub-menu')

                if (subMenu && !parent.classList.contains('submenu-opened')) {
                    e.preventDefault()

                    $subMenuLinks.forEach(link => {
                        let li = link.parentNode
                        li.classList.remove('submenu-opened')
                    })
                    parent.classList.add('submenu-opened')
                }
            }
        })
    })

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})();
