(() => {
    let currentPage = 1; // Keep track of the current page

    const onSuccess = (selector, response) => {
        $(selector).html(response.data);
    };

    const onError = (jqXHR, textStatus, errorThrown) => {
        console.log(textStatus, errorThrown);
    };

    const performAjaxRequest = (data, successSelector) => {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: _wpObject.ajaxUrl,
            data: data,
            success: response => {
                onSuccess(successSelector, response);
            },
            error: onError
        });
    };

    const getPostTypeAndTerm = fromClass => {
        const mappings = {
            'resources': {postType: 'resource', term: 'resource'},
            'reports': {postType: 'resource', term: 'report'},
            'posts': {postType: 'post', term: ''}
        };

        return mappings[fromClass] || {postType: fromClass, term: ''};
    };

    $('.resources-filter-form').on('submit', function(event) {
        event.preventDefault();
        let formData = $(this).serialize();
        currentPage = 1; // Reset to the first page when the form is submitted

        if(!formData.includes('number=')) {
            formData += '&number=10';
        }

        const postType = $(this).data('posttype') || 'newsroom';
        const term = $(this).data('term') || '';
        let selector;

        if (postType === 'resource' && term === 'resource') {
            selector = '.resources .resources-section__copy';
        } else if (postType === 'resource' && term === 'report') {
            selector = '.reports .resources-section__copy';
        } else {
            selector = `.${postType} .resources-section__copy`;
        }

        formData += `&action=filter_resources&paged=${currentPage}`;

        if (postType && term) {
            formData += `&post_type=${postType}&term=${term}`;
        }

        performAjaxRequest(formData, selector);
    });

    // Add click event for pagination buttons
    $('.newsroom, .posts, .resources, .reports').on('click', '.ajax-pagination', function(e) {
        e.preventDefault();

        currentPage = $(this).data('page'); // Update the current page

        // Get current form filters
        let formData = $('.resources-filter-form').serialize();

        if(!formData.includes('number=')) {
            formData += '&number=10';
        }

        const postTypeElement = $(this).closest('.newsroom, .posts, .resources, .reports');
        const postType = postTypeElement.attr('class').split(' ')[0];

        const {postType: truePostType, term} = getPostTypeAndTerm(postType);

        const selector = `.${postType} .resources-section__copy`;
        let pageData = `&action=filter_resources&post_type=${truePostType}&term=${term}&paged=${currentPage}`;

        // Append current filters to the pagination request
        pageData += `&${formData}`;

        performAjaxRequest(pageData, selector);
    });

})();
