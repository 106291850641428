(function () {

    const chartItemsToOpen = document.querySelectorAll('.org-chart__item--with-children .org-chart-card');

    chartItemsToOpen.forEach(card => {
        card.addEventListener('click', function () {
            if (window.innerWidth < 1024) {
                card.nextElementSibling.classList.toggle('opened')
                card.classList.toggle('opened')
            }
        })
    })

})()