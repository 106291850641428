document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./slider');
  require('./menu');
  // require('./featured');
  require('./header')
  require('./diagram')
  require('./org-chart')
  require('./events')
  require('./resources')
  require('./resources-filter')
  require('./search')
  require('./meetings-pagination')
  require('./search-paginate')
  require('./filters')
  require('./gallery-video')
});
// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 1000,
    delay: 300,
    // easing: 'linear',
    easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    // easing: 'ease-in-out-quart',

  });

  let tabs = require('tabs');

  const tabContainers = document.querySelectorAll('.tab-container');
  tabContainers.forEach(container => tabs(container));

  new NativejsSelect({
    selector: '.custom-select'
  });

});
