(function () {
    var currentPage = 1; // Set the initial page

    // Add click event for pagination buttons
    $(document).on('click', '.search-results-list .ajax-pagination', function(e) {
        e.preventDefault();

        currentPage = $(this).data('page'); // Update the current page
        
        var pageData = 'action=search&paged=' + currentPage;
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: _wpObject.ajaxUrl,
            data: pageData,
            success: function(response) {
                if(response.success) {
                        $('.search-results-list .results').html(response.data);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    });
})();
