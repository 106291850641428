;(function () {
  let player

  const tabs = document.querySelectorAll('.video-gallery .video-gallery__tab')
  const videoContainer = document.querySelector('.video-gallery__container')

  if (tabs.length > 0) {
    const initialTab = tabs[0].getAttribute('data-video-id')
    const videoId = initialTab.slice(-11)
    loadPlayer(videoId)
    tabs[0].classList.add('active')
    tabs.forEach((tab) => {
      tab.addEventListener('click', function () {
        if (!tab.classList.contains('active')) {
          let activeEl = document.querySelector('.video-gallery .video-gallery__tab.active')
          if (activeEl) {
            activeEl.classList.remove('active')
          }
          this.classList.add('active')
          const videoLink = tab.getAttribute('data-video-id')
          const videoId = videoLink.slice(-11)
          player.cueVideoById({ videoId: videoId })
        }
      })
    })
  }

  function onYouTubeIframeAPIReady(videoId) {
    player = new YT.Player(videoContainer, {
      height: (videoContainer.offsetWidth * 9) / 16,
      width: videoContainer.offsetWidth,
      videoId: videoId,
    })
  }

  function loadPlayer(videoId) {
    if (typeof YT == 'undefined' || typeof YT.Player == 'undefined') {
      var tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      if (videoContainer) {
        window.onYouTubePlayerAPIReady = function () {
          onYouTubeIframeAPIReady(videoId)
        }
      }
    }
  }
})()
