;(function () {
  $('.hero-slider').slick({
    dots: true,
    appendDots: $('.hero-slider__dots'),
    prevArrow: $('.hero-slider__arrow-prev'),
    nextArrow: $('.hero-slider__arrow-next'),
  })

  $('.gallery-slider').each(function () {
    var $this = $(this)
    $this.slick({
      dots: true,
      appendDots: $this.closest('.gallery-container').find('.gallery-slider__dots'),
      prevArrow: $this.closest('.gallery-container').find('.gallery-slider__arrow-prev'),
      nextArrow: $this.closest('.gallery-container').find('.gallery-slider__arrow-next'),
    })
  })

  $('.post-article-slider').slick({
    dots: false,
    arrows: true,
  })

  $('.twitter-slider').slick({
    slidesToShow: 1,
    centerMode: true,
    arrows: false,
    dots: false,
    variableWidth: true,
  })

  $('.development-features').slick({
    slidesToShow: 1,
    variableWidth: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
        },
      },
    ],
  })
})()
