(function (){
   const featureEvent = document.querySelector('.feature-event');

   if (featureEvent) {
        const featureEventDateMonth = featureEvent.querySelector('.event-card__date .month');
        const featureEventDateDay = featureEvent.querySelector('.event-card__date .date');
        const featureEventTitle = featureEvent.querySelector('.event-card__title');
        const featureEventText = featureEvent.querySelector('.event-card__text');
        const featureEventButton = featureEvent.querySelector('.event-card .cta');

        const eventsListItems = document.querySelectorAll('.events-list .event-item');
        eventsListItems.forEach(event => {
            event.addEventListener('click', function (e) {
                e.preventDefault();
                const month = this.querySelector('.event-item__date .month').textContent;
                const day = this.querySelector('.event-item__date .date').textContent;
                const title = this.querySelector('.event-item__title').textContent;
                const text = this.querySelector('.event-item__text').textContent;
                const link = this.querySelector('.event-item__link').textContent;

                featureEventDateMonth.textContent = month;
                featureEventDateDay.textContent = day;
                featureEventTitle.textContent = title;
                featureEventText.textContent = text;
                featureEventButton.setAttribute('href', link);
            })
        })
   }

})()