(function () {

    const searchForm = document.querySelectorAll('.header-search-form');

    const headerSearch = document.querySelector('.header-search')
    const header = document.querySelector('.header');

    if (headerSearch) {
        const searchOpener = headerSearch.querySelector('.header-search-opener');
        searchOpener.addEventListener('click', function (e) {
            headerSearch.classList.add('opened');
        })
    }

    searchForm.forEach(form => {
        const closeButton = form.querySelector('.close');
        const searchInput = form.querySelector('.search_input');
        const searchResults = headerSearch.querySelector('.search_results');
        closeButton.addEventListener('click', function () {
            if (!form.classList.contains('header-search-form--static')) {
                headerSearch.classList.remove('opened');
                header.classList.remove('search-opened');
                if (searchResults) {
                    searchResults.classList.remove('active');
                    searchResults.style = '';
                }
                searchInput.value = '';
            }
            searchInput.value = '';
        })

    })
})()